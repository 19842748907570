<template>
  <div>
    <v-card>
      <v-card-title class="text-h6">
        {{ $t("labels.detail") }}:
        <span class="error--text">{{ total }} đơn</span>
        <v-spacer></v-spacer>
        <v-btn
          small
          color="success"
          class="pr-0"
          text
          @click="exportExcel"
          v-if="items.length > 0"
        >
          <v-icon>mdi-download</v-icon>
          {{ $t("labels.excel") }}
        </v-btn>
        <v-btn small color="red darken-1" text @click="cancel">
          {{ $t("labels.close") }}
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div>
          <v-simple-table
            fixed-header
            height="calc(100vh - 245px)"
            class="table-padding-2"
          >
            <template v-slot:default>
              <thead class="v-data-table-header">
                <tr>
                  <th
                    role="columnheader"
                    class="text-center"
                    style="min-width: 65px"
                  >
                    {{ $t("labels.pos") }}
                  </th>
                  <th role="columnheader" class="text-center">
                    {{ $t("labels.tracking") }}
                  </th>
                  <th
                    role="columnheader"
                    class="text-center"
                    style="min-width: 95px"
                  >
                    {{ $t("labels.customer") }}
                  </th>
                  <th
                    role="columnheader"
                    class="text-center"
                    style="min-width: 95px"
                  >
                    {{ $t("labels.e_market") }}
                  </th>
                  <th
                    role="columnheader"
                    class="text-center"
                    style="min-width: 95px"
                  >
                    {{ $t("labels.shop") }}
                  </th>
                  <th
                    role="columnheader"
                    class="text-center"
                    style="min-width: 65px"
                  >
                    {{ $t("labels.delivery_company") }}
                  </th>
                  <th
                    role="columnheader"
                    class="text-center"
                    style="min-width: 95px"
                  >
                    <template
                      v-if="['thieu_hang', 'cho_ma_van_chuyen'].includes(state)"
                      >Tạo</template
                    >
                    <template v-else>{{
                      $t("labels.order_status_40")
                    }}</template>
                  </th>
                  <th
                    role="columnheader"
                    class="text-center"
                    style="min-width: 95px"
                  >
                    {{ $t("labels.warehouse_state") }}
                  </th>
                  <th
                    role="columnheader"
                    class="text-center"
                    style="min-width: 95px"
                  >
                    {{ $t("labels.delivery_state") }}
                  </th>
                  <th
                    role="columnheader"
                    class="text-center"
                    style="min-width: 105px"
                  >
                    {{ $t("labels.product") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in items" :key="item.id" class="text-center">
                  <td>{{ item.pos_code }}</td>
                  <td>
                    <OrderTracking :tracking-id="item.tracking_id" />
                    <template v-if="item.internal_request_status">
                      <br />
                      <InternalRequestOrderLink
                        :text="$t('labels.order_request')"
                        :count="1"
                        :is-show-count="false"
                        :tracking_id="item.tracking_id"
                        :status="item.internal_request_status"
                        customClass="fs-12 error--text"
                      />
                    </template>
                  </td>
                  <td>{{ item.company_name }}</td>
                  <td>{{ item.e_market_name }}</td>
                  <td>{{ item.customer_e_market_shop_name }}</td>
                  <td>{{ item.delivery_company }}</td>
                  <td>
                    {{
                      item.receipt_at
                        ? formatDateTime(item.receipt_at)
                        : formatDateTime(item.create_time)
                    }}
                  </td>
                  <td>
                    {{ item.goods_issue_state_name }}
                    <div style="font-size: 11px; color: #666">
                      <span v-if="item.state === 20 || item.state === 35">{{
                        formatDateTime(item.create_time)
                      }}</span>
                      <span v-else-if="item.state === 40">{{
                        formatDateTime(item.receipt_at)
                      }}</span>
                      <span v-else-if="item.state === 60">{{
                        formatDateTime(item.handling_at)
                      }}</span>
                      <span v-else-if="item.state === 100">{{
                        formatDateTime(item.pickup_at)
                      }}</span>
                      <span v-else-if="item.state === 160">{{
                        formatDateTime(item.package_at)
                      }}</span>
                      <span v-else-if="item.state === 200">{{
                        formatDateTime(item.hand_over_at)
                      }}</span>
                      <span v-else-if="item.state === 220">{{
                        formatDateTime(item.confirm_return_at)
                      }}</span>
                      <span v-else-if="item.state === 240">{{
                        formatDateTime(item.receipt_return_at)
                      }}</span>
                    </div>
                  </td>
                  <td>
                    {{ item.delivery_state_name }}
                    <div
                      v-if="item.last_delivery_state_updated"
                      style="font-size: 11px; color: #666"
                    >
                      {{ formatDateTime(item.last_delivery_state_updated) }}
                    </div>
                  </td>
                  <td>
                    <div
                      v-for="(jtem, jdex) in item.items"
                      :key="`${jdex}_${item.tracking_id}_${jtem.id_goods}`"
                      :class="{
                        'error--text':
                          item.missing_items.includes(
                            jtem.customer_goods_barcode
                          ) && item.state === 20,
                      }"
                    >
                      {{ jtem.sku }} x {{ jtem.quantity }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <div class="pt-3">
            <v-pagination
              v-model="page"
              :length="totalPage"
              :total-visible="6"
            ></v-pagination>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { debounce } from "lodash/function";

export default {
  components: {
    OrderTracking: () => import("@/components/common/OrderTracking"),
    InternalRequestOrderLink: () =>
      import("@/components/internal_requests/OrderLink"),
  },
  name: "StatisticalDetail",
  props: {
    idPos: {
      type: [String, Number],
      default: () => null,
    },
    idDeliveryCompany: {
      type: [String, Number],
      default: () => null,
    },
    state: {
      type: [String, Number],
      default: () => null,
    },
    date: {
      type: [String, Array],
      default: () => null,
    },
    idOrders: {
      type: [String, Array],
      default: () => null,
    },
  },
  data: () => ({
    page: 1,
    totalPage: 1,
    total: 0,
    items: [],
    checkedAll: false,
    checkedIds: [],
    checkedId: null,
    filters: {},
    isLoading: false,
    dialogUpdateDelivery: false,
  }),
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    getList: debounce(function () {
      this.checkedId = null;
      this.checkedIds = [];
      this.checkedAll = null;

      httpClient
        .post("/statistical-detail", {
          id_pos: this.idPos,
          id_delivery_company: this.idDeliveryCompany,
          state: this.state,
          date: this.date,
          id_orders: this.idOrders,
          page: this.page,
        })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.total = data.total;
          this.items = [...data.rows].map((r) => {
            const splItems = r.items ? r.items.split(";") : [];
            const oItems = [];
            for (let i = 0; i < splItems.length; i++) {
              const jtems = splItems[i];
              const [id_goods, sku, customer_goods_barcode, quantity] =
                jtems.split("Ω");
              oItems.push({ id_goods, sku, customer_goods_barcode, quantity });
            }
            r.items = [...oItems];
            r.missing_items = r.missing_items ? r.missing_items.split(";") : [];
            return r;
          });
        });
    }, 1000),
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    async exportExcel() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.waiting_file_download"));
        return false;
      }
      this.isLoading = true;
      const filename = `${this.state}.xlsx`;

      try {
        await this.downloadExcelFile(
          "/export-statistical-detail",
          {
            id_pos: this.idPos,
            id_delivery_company: this.idDeliveryCompany,
            state: this.state,
            date: this.date,
          },
          filename
        );
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    urlTrackingDelivery(item) {
      let tracking_link = item.delivery_tracking_link || "";
      if (tracking_link && tracking_link.includes("[delivery_order_id]")) {
        return tracking_link.replace(
          "[delivery_order_id]",
          item.delivery_order_id
        );
      } else {
        return `${tracking_link + item.delivery_order_id}`;
      }
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style scoped></style>
